.alert {
    @include ds-shared.toast();
    box-shadow: none;
    justify-content: flex-start;
    align-items: flex-start;

    &.text-only {
        background-image: none;
        border: none;
        align-items: center;
        padding: ds-shared.spacing() 0;
    }

    .title {
        @include ds-shared.primary-font(bold);
        font-size: var(--size-font-h4-base);
    }

    .flex-column {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
