.user-avatar {
    border-radius: 50%;
    overflow: hidden;
    width: 36px;
    height: 36px;

    &:hover {
        @include ds-shared.shadow();
        cursor: pointer;
    }
}
