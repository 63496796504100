.session-list {
    max-width: 600px;
    width: 100%;

    .session-card {
        width: 100%;
        flex-direction: row;
        padding: 0;
        margin-bottom: ds-shared.spacing();
        padding: ds-shared.spacing(0.5) 0;
    }

    .session-card-body {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .session-text {
        .title {
            @include ds-shared.primary-font(bold);
            font-size: 14px;
            margin-bottom: ds-shared.spacing();
            display: block;
            color: var(--color-font-base);
            flex-grow: 1;
        }

        .description {
            color: var(--color-font-base);
        }

        .current {
            @include ds-shared.border-radius();
            @include ds-shared.primary-font('Normal');
            color: var(--color-font-inverse);
            background-color: var(--color-success-base);
            padding: ds-shared.spacing(0.75) ds-shared.spacing(1.25);
            border-radius: var(--size-indicator-badge);
            margin-left: ds-shared.spacing();
            white-space: nowrap;
        }
    }

    .session-button {
        color: var(--color-danger-base);

        .spinner {
            color: var(--color-font-secondary);
        }

        &:hover:not(:disabled):not(:focus):not(:active) {
            background-color: var(--color-danger-secondary);
        }
    }
}
