.link {
    color: var(--color-primary-base);
    text-decoration: none;

    &:hover {
        color: var(--color-primary-active);
    }
}

.error {
    color: var(--color-danger-base);
}

label {
    font-weight: 500;

    span {
        font-weight: 400;
        color: var(--color-font-base);
    }
}

.text-danger {
    color: var(--color-danger-base);
}

.text-warning {
    color: var(--color-warning-base);
}

.text-success {
    color: var(--color-success-base);
}

h3 {
    font-weight: 500;
    color: var(--color-font-base);
    margin-top: 0;
    margin-bottom: ds-shared.spacing(2);
}

h4 {
    margin-top: 0;
    margin-bottom: ds-shared.spacing(2);
    color: var(--color-font-base);
}

p {
    color: var(--color-font-base);
    margin-bottom: ds-shared.spacing(2);

    &:first-child {
        margin-top: 0;
    }
}

ul,
ol {
    padding-inline-start: 16px;

    li {
        margin-bottom: ds-shared.spacing();
    }
}

strong {
    color: var(--color-font-base);
    @include ds-shared.primary-font(bold);
}

.codes-area {
    @include ds-shared.border-radius();
    background-color: var(--color-secondary-base);
    font-family: 'iA Writer Mono Regular';
    color: var(--color-font-secondary);
    width: 100%;

    .list-unstyled {
        padding-top: ds-shared.spacing();
        padding-left: ds-shared.spacing(1.5);
    }
}

.list-unstyled {
    list-style: none;
    margin: 0;
}

.text-label {
    @include ds-shared.input-group-label();
    margin: 0 spacing() spacing(0.5) 0;

    &.error {
        color: var(--color-danger-base);
    }
}

.descender-container {
    @include ds-shared.input-group-descenders-container();
}
