[class*='button__'] {
    border: none !important;
    &:hover:not(:disabled) {
        @include ds-shared.shadow(action);
    }

    &[class^='secondary__']:not(:disabled),
    &[class^='primary__']:not(:disabled) {
        @include ds-shared.shadow(action);
    }
}

[class*='card__'] {
    @include ds-shared.shadow(small);
    border: 0px;
}

:root {
    .dark {
        --color-border-neutral-base: #8a8f96;
    }

    --color-border-neutral-base: #8a8f96;
}
