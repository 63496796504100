@mixin flexrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@mixin flexcolumn {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}
