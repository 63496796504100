.profile-image {
    @include ds-shared.shadow(small);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 114px;
    height: 114px;
    margin: 0 auto ds-shared.spacing(2);
    background-color: var(--color-structure-secondary);
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 250ms;

    &:hover {
        @include ds-shared.shadow(medium);
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        margin-left: -12px;
        margin-top: -12px;
        border: 3px solid var(--color-font-tooltip);
        border-right: 3px solid transparent;
        border-radius: 100%;
        opacity: 0;
        z-index: 1;
        animation: spin 0.8s infinite linear;
    }

    &:after,
    img {
        transition: opacity 250ms;
        width: 100%;
        height: 100%;
    }

    &.is-loading {
        &:after {
            opacity: 1;
        }

        img {
            opacity: 0.5;
        }
    }
}

.profile-image-overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5px 0;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    color: var(--color-secondary-base);

    .icon {
        display: block;
        margin: 0 auto;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
