@use '~@jamf/design-system-shared/dist/jamf-design-system.bundle' as ds-shared;

#app {
    @include flexcolumn();
}

body {
    background-color: var(--color-secondary-base);
}

.hidden {
    display: none;
}

:root {
    @include ds-shared.light;
    @include ds-shared.core;
}

.dark {
    @include ds-shared.dark;
}
