.card-header {
    padding-bottom: ds-shared.spacing(1.5);
    border-bottom: 1px solid var(--color-border-secondary);

    .card-title {
        @include ds-shared.primary-font(bold);
        letter-spacing: 0.1px;
    }
}

.card-wide {
    width: 464px;

    @include ds-shared.breakpoint(xsmall down) {
        max-width: 464px;
        width: 100%;
    }
}

.card-buttons {
    @include flexrow();
    padding-top: ds-shared.spacing(2);
    justify-content: flex-end;
    align-items: center;

    button:not(:first-child) {
        margin-left: ds-shared.spacing(2);
    }
}
