.navbar-container {
    @include flexrow();
    padding: ds-shared.spacing(3);

    .user-avatar-container {
        align-self: center;
        margin-left: auto;
        padding-right: ds-shared.spacing();

        a {
            text-decoration: none;
            color: var(--color-font-inverse);

            &:hover {
                color: var(--color-font-inverse);
            }
        }
    }

    .logout-button-large {
        display: none;
    }

    @include ds-shared.breakpoint(xsmall up) {
        .logout-button-large {
            display: flex;
        }

        .logout-button-small {
            display: none;
        }
    }
}
