.margin-top {
    margin-top: ds-shared.spacing(2);

    &-large {
        margin-top: ds-shared.spacing(3);
    }

    &-half {
        margin-top: ds-shared.spacing();
    }

    &-quarter {
        margin-top: ds-shared.spacing(0.5);
    }
}

.margin-bottom {
    margin-bottom: ds-shared.spacing(2);

    &-large {
        margin-bottom: ds-shared.spacing(3);
    }

    &-half {
        margin-bottom: ds-shared.spacing();
    }

    &-quarter {
        margin-bottom: ds-shared.spacing(0.5);
    }
}

.margin-right {
    margin-right: ds-shared.spacing(2);

    &-half {
        margin-right: ds-shared.spacing();
    }

    &-quarter {
        margin-right: ds-shared.spacing(0.5);
    }
}

.margin-left {
    margin-left: ds-shared.spacing(2);

    &-half {
        margin-left: ds-shared.spacing();
    }

    &-quarter {
        margin-left: ds-shared.spacing(0.5);
    }
}

.padding-top {
    padding-top: ds-shared.spacing(2);

    &-half {
        padding-top: ds-shared.spacing();
    }
}

.padding-bottom {
    padding-bottom: ds-shared.spacing(2);

    &-half {
        padding-bottom: ds-shared.spacing();
    }
}
.padding-right {
    padding-right: ds-shared.spacing(2);

    &-half {
        padding-right: ds-shared.spacing();
    }
}

.padding-left {
    padding-left: ds-shared.spacing(2);

    &-half {
        padding-left: ds-shared.spacing();
    }
}

.input-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button:not(:first-child),
    input:not(:first-child) {
        margin-left: ds-shared.spacing(2);
    }
}

.justify-flex-start {
    display: flex;
    justify-content: flex-start;
}

.justify-flex-end {
    display: flex;
    justify-content: flex-end;
}

.align-items-center {
    display: flex;
    align-items: center;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-right-auto {
    margin-right: auto;
}
.width {
    input {
        width: 100%;
    }
}
