.auth-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 ds-shared.spacing(3);

    .main-logo {
        position: absolute;
        top: ds-shared.spacing(6);
        width: auto;
        height: auto;
    }
}

.auth-card {
    width: 100%;
    margin-top: calc(#{ds-shared.spacing(12)} + 64px); // 64px = height of logo
    margin-bottom: ds-shared.spacing(6);

    .login-container {
        display: flex;
        flex-direction: column;
    }

    &.wide {
        .login-container {
            flex-direction: row;
        }
    }

    .alt-login-buttons {
        border-top: 1px solid var(--color-border-secondary);
        flex-wrap: wrap;

        button {
            white-space: nowrap;
            margin-top: ds-shared.spacing(2.5);
            margin-left: 0;
        }

        button:not(:last-child) {
            margin-right: ds-shared.spacing(2);
        }
    }

    .register {
        height: 100%;
        margin-left: ds-shared.spacing(-2);
        padding-left: ds-shared.spacing(2);
        border-left: 1px solid var(--color-border-secondary);

        .input-row {
            margin-top: ds-shared.spacing();
        }
    }

    .resend-code-button {
        margin-bottom: ds-shared.spacing(3);
    }
}

@include ds-shared.breakpoint(xsmall) {
    .auth-card {
        max-width: 480px;

        &.wide {
            max-width: 812px;

            .login {
                width: 825px;
            }
        }
    }
}

.sign-in-button {
    color: var(--color-primary-base);
    cursor: pointer;
    height: 100%;

    &:hover {
        color: var(--color-primary-active);
        text-decoration: underline;
    }
}

.register-email-address {
    @include flexrow();
    height: 24px;
    justify-content: flex-start;
    color: var(--color-font-base);
    font-size: var(--size-font-h4-base);
}

@include ds-shared.breakpoint(small down) {
    .auth-card.wide {
        max-width: 480px;

        .login-container {
            flex-direction: column-reverse;
        }

        .login {
            width: auto;
        }

        .register {
            margin-left: 0;
            padding-left: 0;
            margin-bottom: ds-shared.spacing(-2);
            padding-bottom: ds-shared.spacing(2);
            border-left: 0;
            border-bottom: 1px solid var(--color-border-secondary);
        }
    }
}
