.card-header-overview {
    padding: ds-shared.spacing(2.5);
    @include ds-shared.border(var(--color-secondary-active), var(--size-border-width-base), bottom);

    .card-title {
        @include ds-shared.primary-font(bold);
        font-size: 16px;
        line-height: var(--size-font-h4-height);
    }
}

.profile-link-section {
    box-shadow: inset 0 -1px 0 0 var(--color-secondary-active);
    width: 100%;
    @include flexrow();
    align-items: center;
    padding-bottom: ds-shared.spacing(2);
    padding-top: ds-shared.spacing(2);

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        box-shadow: none;
        padding-bottom: 0;
    }

    .section-title {
        @include ds-shared.primary-font(bold);
        font-size: var(--size-font-paragraph-small-base);
        line-height: var(--size-font-paragraph-small-height);
        color: var(--color-font-base);
        padding-bottom: ds-shared.spacing();
    }

    .section-content {
        @include flexcolumn();
        flex-basis: 100%;
        flex: 1;
        justify-content: flex-start;
        color: var(--color-font-base);
        font-size: var(--size-font-h4-base);
        line-height: var(--size-font-h4-height);
    }

    .link {
        //override DS setting
        color: var(--color-primary-base) !important;

        &:hover {
            background-color: var(--color-secondary-base) !important;
            border-color: transparent !important;
        }
    }

    .env-list-name {
        @include ds-shared.primary-font(600);
    }

    .env-list-url {
        font-size: var(--size-font-paragraph-base);
        color: var(--color-font-secondary);
    }
}

.setting-section {
    display: flex;
    align-items: center;

    .section-text {
        @include ds-shared.primary-font();
        font-size: var(--size-font-subtitle-base);
        margin-left: ds-shared.spacing();
        color: var(--color-font-base);
        font-size: 16px;
        line-height: 24px;
    }

    .setting-icon {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }

    .enabled {
        background-color: var(--color-success-base);
    }

    .disabled {
        background-color: var(--color-danger-base);
    }
}

.setting-section-icon {
    display: flex;
    align-items: center;

    .section-text {
        margin-left: ds-shared.spacing();
    }
}
