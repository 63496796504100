$path: '../fonts';

:root {
    --font-family: 'Inter' !important;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$path}/inter-light.woff2') format('woff2');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$path}/inter-regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$path}/inter-semibold.woff2') format('woff2');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('#{$path}/inter-bold.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'iA Writer Mono Regular';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/iAWriterMonoS-Regular.eot') format('eot'),
        url('../fonts/iAWriterMonoS-Regular.woff2') format('woff2'),
        url('../fonts/iAWriterMonoS-Regular.woff') format('woff'),
        url('../fonts/iAWriterMonoS-Regular.ttf') format('truetype');
}

html,
body {
    margin: 0;
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Lucida Grande, sans-serif;
    color: var(--color-font-base);
}
