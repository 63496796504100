$avatar-small: 36px;
$avatar-large: 114px;

.avatar-default {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    color: var(--color-font-inverse);
    @include ds-shared.primary-font(light);
    line-height: 1.2;
    background-color: var(--color-indicator-tag-base);

    &.large {
        font-size: calc(#{$avatar-large} / 3.5);
        width: $avatar-large;
        height: $avatar-large;
    }

    &.small {
        font-size: calc(#{$avatar-small} / 2.5);
        width: $avatar-small;
        height: $avatar-small;
    }
}
