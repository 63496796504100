.card-buttons {
    @include flexrow();
    padding-top: ds-shared.spacing(2);
    justify-content: flex-end;

    .feedback-status {
        display: flex;
        margin-right: auto;

        svg {
            margin-right: ds-shared.spacing();
        }
    }
}
