$inset-box-shadow: inset 0 -1px 0 0 var(--color-structure-secondary);

.table-container {
    width: 100%;
    max-width: 800px;
    min-width: 400px;
}

.table-view {
    @include flexcolumn();
    @include ds-shared.primary-font();
    @include ds-shared.border-radius();
    @include ds-shared.shadow(action);
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 20px;
    overflow: hidden;
    background-color: var(--color-structure-base);

    table {
        @include flexcolumn();
    }

    .pagination {
        @include flexrow();
        box-shadow: $inset-box-shadow;
        height: 48px;
        line-height: 48px;
        padding: ds-shared.spacing() ds-shared.spacing(2);

        .pagination-controls {
            display: inline-flex;
            align-items: center;

            .control-wrapper {
                padding-right: ds-shared.spacing(2);

                .page-number-autcomplete {
                    div,
                    div > input {
                        min-width: 48px;
                        width: 48px;
                    }
                }
            }
        }

        .next-page {
            padding-right: ds-shared.spacing();

            svg {
                transform: rotate(-90deg);
            }
        }

        .previous-page {
            padding-right: ds-shared.spacing();

            svg {
                transform: rotate(90deg);
            }
        }
    }

    .header-row {
        @include ds-shared.primary-font(bold);
        @include ds-shared.shadow();
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;

        //hide last column's resize handle
        tr {
            :last-child .resize {
                display: none;
            }
        }

        .header {
            display: flex;
            position: relative;
            padding: ds-shared.spacing(2);
            text-transform: uppercase;
            text-align: left;

            .resize {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 8px;
                touch-action: none;

                &::after {
                    content: url(./column-resize-handle.svg);
                }
            }
        }
    }

    .row {
        height: 48px;
        align-items: center;
        box-shadow: $inset-box-shadow;

        &:last-of-type {
            box-shadow: none;
        }

        .cell {
            @include ds-shared.ellipsis-overflow();
            padding-left: ds-shared.spacing(2);
            vertical-align: middle;
        }
    }
}
