.main-content {
    display: flex;
    padding: 0 ds-shared.spacing(4) ds-shared.spacing(8) ds-shared.spacing(4);
}

.page-container {
    @include flexcolumn();
    align-items: center;
    width: 100%;
}

.main-content-header {
    @include flexrow();
    justify-content: flex-start;
    align-items: center;
    max-height: 36px;
    line-height: 36px;
    padding: ds-shared.spacing(3) 0 ds-shared.spacing(3) 0;

    & > h1 {
        @include ds-shared.primary-font(600);
        font-size: 24px;
    }

    .content-header-button {
        padding-right: ds-shared.spacing(2);

        .content-header-icon {
            svg {
                transform: rotate(90deg);
            }
        }
    }
}
