.profile-page {
    @include flexcolumn();
    @include ds-shared.primary-font();
    width: 100%;
    align-items: center;

    .profile-display-details {
        @include flexcolumn();
        align-items: center;

        .name {
            @include ds-shared.primary-font();
            padding-top: ds-shared.spacing(2);
            font-size: 24px;
            line-height: 36px;
        }

        .email {
            font-size: 14px;
            letter-spacing: 0.2px;
            line-height: 20px;
            color: var(--color-font-secondary);
        }
    }

    .profile-cards {
        @include flexrow();
        align-items: flex-start;
        justify-content: center;
        width: 100%;

        article:first-child {
            margin-right: ds-shared.spacing(2);
        }

        @include ds-shared.breakpoint(small down) {
            @include flexcolumn();
            align-items: center;

            .card-wide:first-child {
                margin-right: 0;
            }
        }

        @include ds-shared.breakpoint(small up) {
            .card-wide {
                width: 100%;
                max-width: 464px;
            }
        }
    }

    .profile-buttons {
        @include flexrow();
        padding-top: ds-shared.spacing(2);
        padding-bottom: ds-shared.spacing(3);

        button:not(:first-child) {
            margin-left: ds-shared.spacing(2);
        }
    }

    .toggle-button.show {
        svg {
            transform: rotate(180deg);
        }
    }
}

.padding-right {
    padding-right: ds-shared.spacing(2);
}
